@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {

  .cta-image-md {
    background-image: linear-gradient(to left, transparent, #F3F1EB 100%)
  }

}

.doodleFont {
  font-family: 'Righteous', monospace;
}

.caveatfont {
  font-family: 'Nunito', sans-serif;
}



